.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#Nav_menu {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Nav_link:link {
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-decoration: none;
}
.Nav_link:visited {
  color: #fff;
}
.Nav_link:hover {
  color: yellow;
}
.Nav_link:active {
  color: teal;
}

.activeRoute {
  background-color: yellow;
  border-bottom: 0.4rem solid teal;
  cursor: not-allowed;
}
nav img{
  width: 10rem;
}
.nav__cover{
  align-items: center;
  /* justify-content: space-evenly; */
}
.padding-5{
  padding: 5px 0;
}
.container{
  max-width:1240px;
  width:100%;
  margin:0 auto;
  padding:10px 32px;
}
.all__links .link{
  margin-left:2rem;
  font-weight: 700;
}
.nav__section{
  border-bottom: 1px solid;
  box-shadow: 2px 1px 4px #b4b4b4 !important;
  /* border: 2px solid green; */
}
.all__links{
  margin-left: 5rem;
}
.card__width{
  width:40rem
}
.lower__card__width{
  width:20rem;
}
.border{
  /* border: 2px solid green; */
}
*,*::after,*::before{
  box-sizing:border-box;
}
html{
  font-size:15px;
}
.footer{
  background-color: black !important;
  color:white !important;
}
.privacy__content h2{
  font-size: 20px;
  font-weight: 500;
  margin:20px 0px;
}
.privacy__content p{
  margin:10px 0px;
}
.footer__text{
  color: white !important;;
}
.color__white{
  color: #fff!important;
}
.footer__section .link{
  color:white !important;
}
.footer__section{
  padding-top: 2rem;
}
.three__articles__section{
  padding: 2rem 0;
}
.div__comp{
  background-position: center center; 
  background-blend-mode: multiply; 
  background-size: cover;
  /* background-image: linear-gradient(0deg, rgba(230, 230, 230, 1) , rgba(230, 230, 230, 0.432) ) */
}
.article span,.article h1,.article p,.anchor{
  color:rgb(230, 230, 230);
  background-color: rgba(51, 51, 51, 0.671);
}
.article span,.article p,.anchor{
  text-align: left;
}
.side__article h1{
  font-size: 1rem ;
}
.margin__contact__section{
  margin: 10rem;
}
.three__articles__section{
  
}
.three__articles__flex{
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.three__articles__flex .article__card{
  flex:0 1 30%;
}
.read__more{
  color:blue
}
.article__card{
  margin-top: 2rem;
}
.article__overview p{
  font-size: 20px;
}
.article__overview .summary{
  font-size:1.2rem;
  margin-top:2rem;
}
.article__overview .figure{
  margin-top: 2rem;
}
#active{
  color: blue;
}
#inActive{
  color:grey;
}
#show{
  display: block;
}
#hide{
  display: none;
}
.about_content p{
  font-size: 1.2rem;
  margin:1rem 0;
}
.about_content h2{
  font-size: 1.5rem;
  margin:1rem 0;
  font-weight: 600;
}
/* .gdrive__image{
  width:70rem;
} */
.gdrive__image{
  width:45rem;
  /* border: 2px solid green; */
}
.trending__pic{
  width: 20rem;
  margin-left: 1rem;
}
@media only screen and (min-width: 1400px) {
  #hide{
    display: block!important;
  }
}
@media screen and (max-width: 1400px) {
  .gdrive__image{
    width:20rem;
    /* border: 2px solid green; */
  }
}
@media screen and (max-width: 1200px) {
  .gdrive__image{
    width:35rem;
    /* border: 2px solid green; */

  }
}
@media screen and (max-width: 600px) {

    html{
        font-size:8px;
    }
  .three__articles__flex{
    flex-direction: column!important;
  }
}
@media screen and (max-width: 460px) {
  .gdrive__image{
    width:20rem;
    /* border: 2px solid green; */
  }
}
.para__margin{
  margin-bottom: 20rem !important;
}

/* loader styles*/
.pos-center {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3d3d3d;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* advertisements css */
body{
  position:relative
}
.leader__board__one{
  /* border:2px solid green; */
  max-width:1240px;
  width:100%;
  margin:0 auto;
  padding:10px 32px;
  display: flex;
  justify-content: center;
}
.leader__board__two{
  /* border:2px solid green; */
  max-width:1240px;
  width:100%;
  margin:0 auto;
  padding:10px 32px;
  display: flex;
  justify-content: center;
}
.leader__board__three{
  /* border:2px solid green; */
  max-width:1240px;
  width:100%;
  margin:0 auto;
  padding:10px 32px;
  display: flex;
  justify-content: center;
}
.midpage__unit__three__updated{
  /* border:2px solid green; */
  position: absolute;
  top:40rem;
  right:1rem;
  z-index: 100;
}
.midpage__unit__one{
  /* border:2px solid green; */
  position: absolute;
  top:40rem;
  left:1rem;
  z-index: 100;
}
.midpage__unit__two{
  /* border:2px solid green; */
  position: absolute;
  top:15rem;
  right:1rem;
  z-index: 100;
}
.midpage__unit__three{
  /* border:2px solid green; */
  position: absolute;
  top:100rem;
  right:1rem;
  z-index: 100;
}
.midpage__unit__four{
  /* border:2px solid green; */
  position: absolute;
  top:140rem;
  left:1rem;
  z-index: 100;
}
@media screen and (max-width: 600px) {
  .leader__board__one{
    display: none;
  }
  .leader__board__two{
    display: none;
  }
  .leader__board__three{
    display: none;
  }
  .minimize__source,.minimize__date{
    font-size: 1.5rem!important;
  }
  .midpage__unit__one{
    display: none;
  }
  .midpage__unit__three{
    display: none;
  }
}